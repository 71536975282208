import styled, { css } from 'styled-components';
import { spacer } from '~/theme/patterns';
import { mq } from '~/theme/patterns';
import validateProps from '~/utils/validateProps';

const PaintingStyled = styled.div<{
  theme?: object;
}>`
  ${({ theme }) => {
    const props = {
      title: theme?.typeStyles?.h2,
    };

    validateProps('PaintingStyled', { ...props });

    return css`
      ${spacer('large', 'padding-top-bottom')}

      text-align:center;

      @media ${mq.medium} {
        text-align: left;
      }

      .title {
        ${props.title}

        &.hide-mobile {
          display: none;

          @media ${mq.medium} {
            display: block;
          }
        }

        &.hide-desktop {
          @media ${mq.medium} {
            display: none;
          }
        }
      }

      .content {
        margin-top: 1.6rem;
      }

      .image {
        margin-top: 2.4rem;
        margin-left: auto;
        margin-right: auto;
        max-width: 45rem;

        @media ${mq.medium} {
          margin-top: 0;
        }
      }
    `;
  }}
`;

export default PaintingStyled;
