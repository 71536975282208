import React from 'react';
import PaintingStyled from './painting.styled';

import { ContentTypes } from '~/schema/types';
import Markup from '~/components/markup';
import { Column, Container, Row } from '~/components/layout';
import Paypal from '~/components/paypal';
import Image from '~/components/image';
import SchemaOrg from '~/components/schemaOrg';
import { SchemaOrgTypes } from '~/components/schemaOrg/schemaOrg.types';

const Painting = (props: { pageContext: ContentTypes.Painting }) => {
  const {
    description,
    image,
    location,
    medium,
    paypalEmbedCode,
    referenceNumber,
    title,
  } = props.pageContext;

  const PaintingSchema: SchemaOrgTypes.Schema_Painting = {
    '@type': 'Painting',
    name: title,
    image: image.url_webp,
    artMedium: medium,
    contentLocation: location,
  };

  return (
    <>
      <SchemaOrg schema={PaintingSchema} />
      <PaintingStyled>
        <Container>
          <Row>
            <Column columns={{ medium: 6 }}>
              <h1 className="title hide-desktop">
                {title} - {referenceNumber}
              </h1>
              <div className="image">
                <Image {...image} />
              </div>
            </Column>
            <Column columns={{ medium: 6 }}>
              <h1 className="title hide-mobile">
                {title} - {referenceNumber}
              </h1>
              {description && <Markup className="content" html={description} />}
              <Paypal embedCode={paypalEmbedCode} />
            </Column>
          </Row>
        </Container>
      </PaintingStyled>
    </>
  );
};

export default Painting;
